import { ActionUnion } from "modules/redux/models";
import { opportunitiesActions } from "./actions";

export interface OpportunitiesInitState {
  isLoading: boolean;
  allOpportunities: AllOpportunity[];
  managedOpportunities: ManagedOpportunity[];
  featuredOpportunities: FeaturedOpportunity[];
  joinedOpportunities: JoinedOpportunity[];
  error?: any;
}

const INITIAL_STATE: OpportunitiesInitState = {
  isLoading: false,
  allOpportunities: [],
  managedOpportunities: [],
  featuredOpportunities: [],
  joinedOpportunities: [],
};

export const opportunitiesReducer = (
  state: OpportunitiesInitState = INITIAL_STATE,
  action: ActionUnion<typeof opportunitiesActions>
): OpportunitiesInitState => {
  switch (action.type) {
    case "opportunities/LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "opportunities/ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case "opportunities/SET_ALL_OPPORTUNITIES":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        allOpportunities: [...action.payload],
      };

    case "opportunities/SET_MANAGED_OPPORTUNITIES":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        managedOpportunities: [...action.payload],
      };

    case "opportunities/SET_FEATURED_OPPORTUNITIES":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        featuredOpportunities: [...action.payload],
      };

    case "opportunities/SET_JOINED_OPPORTUNITIES":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        joinedOpportunities: [...action.payload],
      };

    case "opportunities/PIPELINES_RESET":
      return INITIAL_STATE;

    default:
      return state || INITIAL_STATE;
  }
};
