// https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/3-Authorization-II/2-call-api-b2c/SPA/src/authConfig.js

import { LogLevel, RedirectRequest } from "@azure/msal-browser";

const localApiBaseUrl = process.env.BASE_API_URL;
const providerApiBaseUrl = process.env.BASE_AUTH_URL;

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_susi",
    forgotPassword: "B2C_1_reset",
    editProfile: "B2C_1_edit_profile",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://volonteros.b2clogin.com/volonteros.onmicrosoft.com/B2C_1_susi",
    },
    forgotPassword: {
      authority:
        "https://volonteros.b2clogin.com/volonteros.onmicrosoft.com/B2C_1_reset",
    },
    editProfile: {
      authority:
        "https://volonteros.b2clogin.com/volonteros.onmicrosoft.com/B2C_1_edit_profile",
    },
  },
  authorityDomain: "volonteros.b2clogin.com",
};

export const msalConfig = {
  auth: {
    clientId: "6d078fe2-3724-4199-9728-a55d3c0aca87", // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.BASE_WEB_URL, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: process.env.BASE_WEB_URL, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [`${providerApiBaseUrl}/Volonteros.UserProfile`],
};

export const updateProfile = {
  url: `${localApiBaseUrl}/UserProfile/SaveMyUserProfile`,
};

//   // Add the endpoints here for Microsoft Graph API services you'd like to use.
//   export const graphConfig = {
//       graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
//   };
