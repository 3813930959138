import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "authConfig";

export const acquireAccessToken = async (
  msalInstance: IPublicClientApplication
) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    throw new Error("User is not logged in");
  }

  const request = {
    ...loginRequest,
    account: activeAccount || accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};
