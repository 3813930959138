import { AppState } from "modules/redux/models";

const selectJoinedApprovedOngoingOpportunities = (state: AppState) =>
  state.tasks.joinedApprovedOngoingOpportunities;

const selectOpportunityTasks = (state: AppState) =>
  state.tasks.opportunityTasks;

export const tasksSelector = {
  selectJoinedApprovedOngoingOpportunities,
  selectOpportunityTasks,
};
