import { ActionUnion } from "modules/redux/models";
import { tasksActions } from "./actions";

export interface TasksInitState {
  isLoading: boolean;
  joinedApprovedOngoingOpportunities: JoinedApprovedOngoingOpportunity[];
  opportunityTasks: GetOpportunityTasksForOpportunity;
  error?: any;
}

const INITIAL_STATE: TasksInitState = {
  isLoading: false,
  joinedApprovedOngoingOpportunities: [],
  opportunityTasks: {
    numberOfTeams: 0,
    numberOfVolunteers: 0,
    opportunityTitle: "",
    toDoTasks: [],
    inProgressTasks: [],
    doneTasks: []
  },
};

export const tasksReducer = (
  state: TasksInitState = INITIAL_STATE,
  action: ActionUnion<typeof tasksActions>
): TasksInitState => {
  switch (action.type) {
    case "tasks/LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "tasks/ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case "tasks/SET_JOINEDAPPROVEDONGOING_OPPORTUNITIES":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        joinedApprovedOngoingOpportunities: [
          ...action.payload,
        ],
      };

    case "tasks/SET_OPPORTUNITY_TASKS":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        opportunityTasks: {
          ...action.payload
        },
      };

    case "tasks/PIPELINES_RESET":
      return INITIAL_STATE;

    default:
      return state || INITIAL_STATE;
  }
};
