import { ActionUnion } from "modules/redux/models";
import { userActions } from "./actions";

export interface UserInitState {
  isLoading: boolean;
  user?: User;
  error?: any;
}

const INITIAL_STATE: UserInitState = {
  isLoading: false,
  user: {
    firstName: "",
    lastName: "",
    assignedId: "",
    email: "",
    dateOfBirth: "",
    phoneNumber: "",
    address: "",
    tShirtSize: "",
    photoFileName: "",
    photoFileUrl: "",
  },
};

export const userReducer = (
  state: UserInitState = INITIAL_STATE,
  action: ActionUnion<typeof userActions>
): UserInitState => {
  switch (action.type) {
    case "user/LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "user/ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case "user/SET_USER":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        user: {
          ...state.user,
          ...action.payload },
      };

    case "user/PIPELINES_RESET":
      return INITIAL_STATE;

    default:
      return state || INITIAL_STATE;
  }
};
