import { AppState } from "modules/redux/models";

const selectAllOpportunities = (state: AppState) =>
  state.opportunities.allOpportunities;

const selectManagedOpportunities = (state: AppState) =>
  state.opportunities.managedOpportunities;

const selectFeaturedOpportunities = (state: AppState) =>
  state.opportunities.featuredOpportunities;

const selectJoinedOpportunities = (state: AppState) =>
  state.opportunities.joinedOpportunities;

export const opportunitiesSelector = {
  selectAllOpportunities,
  selectManagedOpportunities,
  selectFeaturedOpportunities,
  selectJoinedOpportunities,
};
