/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./src/authConfig";
import { StoreProvider } from "./src/modules/redux/components/StoreProvider";

import "./src/assets/styles/styles.scss";

// Create a PublicClientApplication instance using the configuration from authConfig
const msalInstance = new PublicClientApplication(msalConfig);

export const wrapRootElement = ({ element }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <StoreProvider>{element}</StoreProvider>
    </MsalProvider>
  );
};
