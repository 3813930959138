import { ActionUnion } from "modules/redux/models";
import { sessionActions } from "./actions";

interface Session {
  accessToken: string;
}

export interface SessionInitState {
  isLoading: boolean;
  session: Session;
  error?: any;
}
const INITIAL_STATE = {
  isLoading: false,
  session: {
    accessToken: "",
  },
};

export const sessionReducer = (
  state = INITIAL_STATE,
  action: ActionUnion<typeof sessionActions>
) => {
  switch (action.type) {
    case "session/LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "session/ERROR":
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case "session/SET_SESSION":
      return {
        ...state,
        isLoading: false,
        error: undefined,
        session: {
          accessToken: action.payload,
        },
      };

    case "session/PIPELINES_RESET":
      return INITIAL_STATE;

    default:
      return state || INITIAL_STATE;
  }
};
