import { getMyUserProfile } from "./../http/getUser";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userActions } from "..";

export const useGetMyUserProfile = () => {
  const dispatch = useDispatch();

  const getUserFn = () => {
    dispatch(userActions.loading);

    getMyUserProfile()
      .then(({ user }) => dispatch(userActions.setUser(user)))
      .catch((error) => {
        dispatch(userActions.error(error));
      });
  };

  useEffect(getUserFn, []);

  return { getUser: getUserFn };
};
