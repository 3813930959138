import React from "react";
import { Provider } from "react-redux";
import { createReduxStore } from "../factories/createReduxStore";

/**
 * @description Provider component that generates the redux store which wraps the whole application
 * @param children root element provided by Gatsby
 */

export const StoreProvider: React.FC = ({ children }) => {
  const { store } = createReduxStore();

  return <Provider store={store}>{children}</Provider>;
};
