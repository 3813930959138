import { createAction } from "modules/redux/factories";

export const tasksActions = {
  setJoinedApprovedOngoingOpportunities: (payload: JoinedApprovedOngoingOpportunity[]) =>
    createAction("tasks/SET_JOINEDAPPROVEDONGOING_OPPORTUNITIES", payload),
  setOpportunityTasks: (payload: GetOpportunityTasksForOpportunity) =>
    createAction("tasks/SET_OPPORTUNITY_TASKS", payload),
  loading: () => createAction("tasks/LOADING"),
  error: (payload: any) => createAction("tasks/ERROR", payload),

  reset: () => createAction("tasks/PIPELINES_RESET"),
};
