import { createAction } from "modules/redux/factories";

export const opportunitiesActions = {
  setAllOpportunities: (payload: AllOpportunity[]) =>
    createAction("opportunities/SET_ALL_OPPORTUNITIES", payload),
  setManagedOpportunities: (payload: ManagedOpportunity[]) =>
    createAction("opportunities/SET_MANAGED_OPPORTUNITIES", payload),
  setFeaturedOpportunities: (payload: FeaturedOpportunity[]) =>
    createAction("opportunities/SET_FEATURED_OPPORTUNITIES", payload),
  setJoinedOpportunities: (payload: JoinedOpportunity[]) =>
    createAction("opportunities/SET_JOINED_OPPORTUNITIES", payload),
  loading: () => createAction("opportunities/LOADING"),
  error: (payload: any) => createAction("opportunities/ERROR", payload),

  reset: () => createAction("opportunities/PIPELINES_RESET"),
};
