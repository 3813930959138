import { opportunitiesReducer } from "modules/opportunities/redux";
import { userReducer } from "modules/user";
import {
  applyMiddleware,
  combineReducers,
  createStore,
  ReducersMapObject,
  Store,
} from "redux";
import { AppState } from "../models";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { sessionReducer } from "modules/app/session/store/reducer";
import { tasksReducer } from "modules/tasks/redux";

export const createReduxStore = (): { store: Store } => {
  /**
   * @description Merge all reducers into a single object
   */
  const rootReducer: ReducersMapObject<AppState> = {
    session: sessionReducer,
    user: userReducer,
    opportunities: opportunitiesReducer,
    tasks: tasksReducer,
  };

  const reducers = combineReducers(rootReducer);
  const loggerMiddleware = createLogger({ collapsed: true });

  const store: Store = createStore(
    reducers,
    applyMiddleware(loggerMiddleware, thunkMiddleware)
  );

  return {
    store: store,
  };
};
